import { LayoutProps } from "../../components/LayoutWrapper";
import * as React from 'react';
import { HeaderMenu } from "../../components/HeaderMenu";
import { Button, Grid, Header, Icon, Input, Loader, Message, Responsive, Segment, Table } from "semantic-ui-react";
import { ResponsiveElement } from "../../components/ResponsiveElement";
import { Helpers } from "../../utils/helpers";
import axios from 'axios';
import { SortimentInfo, SortimentInfoForm } from "../../components/SortimentInfo";
import { groupBy, mapValues } from 'lodash';


interface SortimentCreateState {
    loading: boolean;
    serverErrors: {[field: string]: string[]}
}

interface SortimentCreateProps extends LayoutProps{
}

export class SortimentCreate extends React.Component<SortimentCreateProps, SortimentCreateState> {
    constructor(props) {
        super(props);
        console.log("SortimentCreate");
        this.state = {
            loading: false,
            serverErrors: {}
        };
    }

    createSortiment = async (sortiment: SortimentInfo) => {
        this.setState({
            loading: true
        });
        console.log(sortiment);

        try{
            const response = await axios.post('/api/sortiments', sortiment, {
                headers: {
                    Authorization: Helpers.getSessionId()
                },
            });

            Helpers.showSuccess('Sortiments veiksmīgi izveidots!');
            this.props.history.push(`/sortiments/${response.data.data}`);

            //redirect to edit page with success message
        }catch(error){
            this.setState({
                loading: false
            });
            if(error.response && error.response.status === 400){
                const errors = error.response.data.errors;

                this.setState({
                    serverErrors: mapValues(groupBy(errors,error => error.param), errors => errors.map(error => error.msg))
                })

                console.log(mapValues(groupBy(errors,error => error.param), errors => errors.map(error => error.msg)))

                console.log(this.state.serverErrors);
            }else{
                Helpers.showError("Kaut kas nogāja greizi!");
            }
        }
    }

    onValueChanged = (field) => {
        const errors = this.state.serverErrors;
        errors[field] = [];
        this.setState({
            serverErrors: errors
        });
    }

    render() {
        return (
            <div>
                <header style={{ background: "#fff" }}>
                    <div className="layout-wrapper">
                        <HeaderMenu { ...this.props }/>
                    </div>
                </header>

                <div className="layout-wrapper">
                    <SortimentInfoForm onSubmit={this.createSortiment} type='create' loading={this.state.loading} onValueChanged={this.onValueChanged} serverErrors={this.state.serverErrors}/>
                </div>
            </div>
        )
    }
}

