import * as React from "react";
import cookie from "react-cookies";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { AcceptJob } from "../../containers/AcceptJob";
import { StartJob } from "../../containers/StartJob";
import { FinishJob } from "../../containers/FinishJob";
import { Properties } from "../../containers/Properties";
import { ActiveUser } from "../App";
import { UserList } from "../../containers/UserList";
import { Responsive } from "semantic-ui-react";
import { UserCreate } from "../../containers/UserCreate";
import { UserEdit } from "../../containers/UserEdit";
import { ChangePassword } from "../../containers/ChangePassword";
import { SortimentList } from "../../containers/SortimentList";
import { SortimentEdit } from "../../containers/SortimentEdit";
import { SortimentCreate } from "../../containers/SortimentCreate";

export interface LayoutProps {
    activeUser: ActiveUser | null;
    resetAppState(): void;
    setActiveType(param: number): void;
    getActiveType(): number;
    showMobileMenu(): void;
    hideMobileMenu(): void;
    history: any[];
}

export class LayoutWrapper extends React.Component<LayoutProps, {}> {
    logout = () => {
        cookie.remove("ozols-forest-token");
        this.props.resetAppState();
    }

    render() {
        console.log(this.props.activeUser)
        return (
            <div style={{ height: "100vh" }}>
                <Router>
                    <Switch>
                        <Route exact path="/" component={routeProps => <Properties {...this.props} {...routeProps}/>}/>
                        <Route path="/brauciens/:jobId/pienemt" component={routeProps => <AcceptJob {...this.props} {...routeProps}/>}/>
                        <Route path="/brauciens/:jobId/sakt" component={routeProps => <StartJob {...this.props} {...routeProps}/>}/>
                        <Route path="/brauciens/:jobId" component={routeProps => <FinishJob {...this.props} {...routeProps}/>}/>
                        <Route exact path="/users" component={routeProps => <UserList {...this.props} {...routeProps}/>}/>
                        <Route exact path="/users/create" component={routeProps => <UserCreate {...this.props} {...routeProps}/>}/>
                        <Route exact path="/users/change-password" component={routeProps => <ChangePassword {...this.props} {...routeProps} userId={this.props.activeUser!.userId} />}/>
                        <Route path="/users/:userId" component={routeProps => <UserEdit {...this.props} {...routeProps}/>}/>
                        <Route exact path="/sortiments" component={routeProps => <SortimentList {...this.props} {...routeProps}/>}/>
                        <Route path="/sortiments/:sortimentId" component={routeProps => <SortimentEdit {...this.props} {...routeProps}/>}/>
                        <Route exact path="/sortiment/create" component={routeProps => <SortimentCreate {...this.props} {...routeProps}/>}/>
                    </Switch>
                </Router>
            </div>
        );
    }
}
