import * as React from "react";
import { Button, Checkbox, Form, Input, Label, Message } from "semantic-ui-react";
import { createForm, formShape } from 'rc-form';
import { values, concat, flatten } from 'lodash';
import { showCompletionScript } from "yargs";

export interface SortimentInfo {
    title: string;
    key: string;
    nav_key: string;
    sortiments_papinet_id: string;
}

interface SortimentInfoProps {
    onSubmit(papinetInfo: SortimentInfo): void;
    form: any;
    type: 'create' | 'edit';
    initialSortimentInfo?: SortimentInfo;
    loading: boolean;
    serverErrors: {[field: string]: string[]};
    onValueChanged: (field: string) => void;
}

interface SortimentInfoState {
    haveError: boolean;
}

class SortimentInfoFormWrapper extends React.Component<SortimentInfoProps, SortimentInfoState> {
    constructor(props){
        super(props);
        console.log(this.props)
        this.state = {
            haveError: false
        }
    }

    onSubmit = async (e) => {        
        e.preventDefault();
        console.log(this);
        let formData;
        //validate
        try{
            formData = await new Promise((resolve, reject) => this.props.form.validateFields((err, values) => { if(err){ reject(err) } else { resolve(values) }})) as any;
            console.log(flatten(values(this.props.serverErrors)));
            if(flatten(values(this.props.serverErrors)).length > 0){
                throw new Error('Server side errors not fixed!');
            }
        }catch(e){
            console.log(e);
            this.setState({
                haveError: true
            });
            return;
        }
        this.setState({
            haveError: false
        });
        
        console.log(formData)
        this.props.onSubmit(formData);
    }

    fieldErrors = (field: string): string[] | null => {
        const { getFieldError } = this.props.form;
        const errors = (getFieldError(field) || []).concat(this.props.serverErrors[field] || []);
        if(errors.length === 0) return null;
        return errors;
    }

    formHasErrors = (): boolean => {
        return values(this.props.form.getFieldsError()).filter(errors => !!errors).concat(flatten(values(this.props.serverErrors))).length > 0;
    }

    render() {
        const { getFieldProps, getFieldError } = this.props.form;
        const titleInput = {
            ...getFieldProps("title", {
                rules: [{
                    required: true,
                    message: "Lūdzu ievadiet epastu!",
                }],
                initialValue: this.props.initialSortimentInfo?.title,
                onChange: () => { this.props.onValueChanged('email') }
            })
        };

        const keyInput = {
            ...getFieldProps("key", {
                rules: [{
                    required: true,
                    message: "Lūdzu ievadiet lietotājvārdu!",
                }],
                initialValue: this.props.initialSortimentInfo?.key,
                onChange: () => { this.props.onValueChanged('key') }
            })
        };

        const navKeyInput = {
            ...getFieldProps("nav_key", {
                rules: [{
                    required: true,
                    message: "Lūdzu ievadiet sastāva kodu!",
                }],
                initialValue: this.props.initialSortimentInfo?.nav_key,
                onChange: () => { this.props.onValueChanged('nav_key') }
            })
        };

        const papinetIdInput = {
            ...getFieldProps("sortiments_papinet_id", {
                rules: [{
                    required: this.props.type === 'create',
                    message: "Lūdzu ievadiet paroli!",
                }],
                initialValue: this.props.initialSortimentInfo?.sortiments_papinet_id,
                onChange: () => { this.props.onValueChanged('sortiments_papinet_id') }
            })
        };

        return (
            <Form loading={this.props.loading} error={this.formHasErrors()}>
                <Form.Field required>
                    <label>Title</label>
                    <Input placeholder='Title' { ...titleInput }/>
                    <Message
                        error
                        content={this.fieldErrors('title')}
                    />
                </Form.Field>
                <Form.Field required>
                    <label>Key</label>
                    <Input placeholder='Key' { ...keyInput }/>
                    <Message
                        error
                        content={this.fieldErrors('key')}
                    />
                </Form.Field>
                <Form.Field required>
                    <label>Nav key</label>
                    <Input placeholder='Nav key' { ...navKeyInput }/>
                    <Message
                        error
                        content={this.fieldErrors('nav_key')}
                    />
                </Form.Field>
                <Form.Field required>
                    <label>Papinet ID</label>
                    <Input placeholder='Papinet ID' { ...papinetIdInput }/>
                    <Message
                        error
                        content={this.fieldErrors('papinet_id')}
                    />
                </Form.Field>
                <Button type='submit' onClick={this.onSubmit}>Saglabāt</Button>
            </Form>
        );
    }
}

export const SortimentInfoForm = createForm()(SortimentInfoFormWrapper)