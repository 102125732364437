import * as React from "react";
import { Button, Checkbox, Form, Input, Label, Message } from "semantic-ui-react";
import { createForm, formShape } from 'rc-form';
import { values, concat, flatten } from 'lodash';
import { showCompletionScript } from "yargs";

export interface UserInfo {
    first_name: string;
    last_name: string;
    email: string;
    username: string;
    admin: boolean;
    car_number: string;
    password?: string;
}

interface UserInfoProps {
    onSubmit(userInfo: UserInfo): void;
    form: any;
    type: 'create' | 'edit';
    initialUserInfo?: UserInfo;
    loading: boolean;
    serverErrors: {[field: string]: string[]};
    onValueChanged: (field: string) => void;
}

interface UserInfoState {
    haveError: boolean;
}

class UserInfoFormWrapper extends React.Component<UserInfoProps, UserInfoState> {
    constructor(props){
        super(props);
        console.log(this.props)
        this.state = {
            haveError: false
        }
    }

    onSubmit = async (e) => {        
        e.preventDefault();
        console.log(this);
        let formData;
        //validate
        try{
            formData = await new Promise((resolve, reject) => this.props.form.validateFields((err, values) => { if(err){ reject(err) } else { resolve(values) }})) as any;
            console.log(flatten(values(this.props.serverErrors)));
            if(flatten(values(this.props.serverErrors)).length > 0){
                throw new Error('Server side errors not fixed!');
            }
        }catch(e){
            console.log(e);
            this.setState({
                haveError: true
            });
            return;
        }
        this.setState({
            haveError: false
        });
        
        console.log(formData)
        this.props.onSubmit(formData);
    }

    fieldErrors = (field: string): string[] | null => {
        const { getFieldError } = this.props.form;
        const errors = (getFieldError(field) || []).concat(this.props.serverErrors[field] || []);
        if(errors.length === 0) return null;
        return errors;
    }

    formHasErrors = (): boolean => {
        return values(this.props.form.getFieldsError()).filter(errors => !!errors).concat(flatten(values(this.props.serverErrors))).length > 0;
    }

    render() {
        console.log(this.props.initialUserInfo?.email);
        const { getFieldProps, getFieldError } = this.props.form;
        const emailInput = {
            ...getFieldProps("email", {
                rules: [{
                    required: true,
                    message: "Lūdzu ievadiet epastu!",
                }],
                initialValue: this.props.initialUserInfo?.email,
                onChange: () => { this.props.onValueChanged('email') }
            })
        };

        const usernameInput = {
            ...getFieldProps("username", {
                rules: [{
                    required: true,
                    message: "Lūdzu ievadiet lietotājvārdu!",
                }],
                initialValue: this.props.initialUserInfo?.username,
                onChange: () => { this.props.onValueChanged('username') }
            })
        };

        const carNumberInput = {
            ...getFieldProps("car_number", {
                rules: [{
                    required: true,
                    message: "Lūdzu ievadiet sastāva kodu!",
                }],
                initialValue: this.props.initialUserInfo?.car_number,
                onChange: () => { this.props.onValueChanged('car_number') }
            })
        };

        const passwordInput = {
            ...getFieldProps("password", {
                rules: [{
                    required: this.props.type === 'create',
                    message: "Lūdzu ievadiet paroli!",
                }],
                onChange: () => { this.props.onValueChanged('password') }
            })
        };

        const firstNameInput = {
            ...getFieldProps("first_name", {
                initialValue: this.props.initialUserInfo?.first_name,
                onChange: () => { this.props.onValueChanged('first_name') }
            })
        };

        const lastNameInput = {
            ...getFieldProps("last_name", {
                initialValue: this.props.initialUserInfo?.last_name,
                onChange: () => { this.props.onValueChanged('last_name') }
            })
        };


        const adminInput = {
            ...getFieldProps("admin", {
                rules: [],
                valuePropName: 'checked',
                initialValue: this.props.initialUserInfo?.admin || false,
                onChange: () => { this.props.onValueChanged('admin') },
                getValueFromEvent: function (e) {
                    if (!e || !e.target) {
                      return e;
                    }
                    const { target } = e;
                    return target.tagName === 'LABEL' ? !target.parentNode.classList.contains('checked') : target.value;
                  }
            })
        };

        console.log(adminInput);

        return (
            <Form loading={this.props.loading} error={this.formHasErrors()}>
                <Form.Field required>
                    <label>Epasts</label>
                    <Input placeholder='Epasts' type="email" { ...emailInput }/>
                    <Message
                        error
                        content={this.fieldErrors('email')}
                    />
                </Form.Field>
                <Form.Field required>
                    <label>Lietotājvārds</label>
                    <Input placeholder='Lietotājvārds' { ...usernameInput }/>
                    <Message
                        error
                        content={this.fieldErrors('username')}
                    />
                </Form.Field>
                <Form.Field required>
                    <label>Sastāva kods</label>
                    <Input placeholder='Sastāva kods' { ...carNumberInput }/>
                    <Message
                        error
                        content={this.fieldErrors('car_number')}
                    />
                </Form.Field>
                { this.props.type === 'edit' && 
                    <Form.Field disabled>
                        <label>Parole</label>
                        <Input placeholder='Parole' type="password" value="password"/>
                    </Form.Field>
                }
                <Form.Field required={ this.props.type === 'create' }>
                    <label> { this.props.type === 'create' ? 'Parole' : 'Jaunā parole' } </label>
                    <Input placeholder='Jaunā parole' type="password" { ...passwordInput }/>
                    <Message
                        error
                        content={this.fieldErrors('password')}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Vārds</label>
                    <Input placeholder='Vārds' { ...firstNameInput } />
                </Form.Field>
                <Form.Field>
                    <label>Uzvārds</label>
                    <Input placeholder='Uzvārds' { ...lastNameInput }/>
                </Form.Field>
                <Form.Field>
                    <Checkbox label='Administrators' { ...adminInput }/>
                </Form.Field>
                <Button type='submit' onClick={this.onSubmit}>Saglabāt</Button>
            </Form>
        );
    }
}

export const UserInfoForm = createForm()(UserInfoFormWrapper)