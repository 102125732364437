import axios from "axios";
import * as React from "react";
import { NavLink, withRouter } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Message from "semantic-ui-react/dist/commonjs/collections/Message";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";
import Loader from "semantic-ui-react/dist/commonjs/elements/Loader";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";

import { HeaderMenu } from "../../components/HeaderMenu";
import { LayoutProps } from "../../components/LayoutWrapper";
import { ResponsiveElement } from "../../components/ResponsiveElement";
import { JOB_TYPE_LABEL, JOB_TYPES } from "../../utils/constants";
import { Helpers } from "../../utils/helpers";

import { PropertyListItem } from "./types/property";

interface PropertiesState {
    properties: PropertyListItem[];
    loaded: boolean;
    searchKeyword: string;
}

interface PropertiesProps extends LayoutProps {
    history: any[];
}

class PropertiesBase extends React.Component<PropertiesProps, PropertiesState> {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            properties: [],
            searchKeyword: "",
        };

        document.title = "Aktīvie braucieni";
    }

    componentDidMount() {
        this.fetchProperties();
    }

    refreshProperties = () => {
        this.setState({ loaded: false }, () => {
            this.fetchProperties();
        });
    }

    fetchProperties = () =>
        axios.get(`/api/jobs`, {
            headers: {
                Authorization: Helpers.getSessionId(),
                ActiveType: this.props.getActiveType(),
            },
        })
        .then(response => {
            this.setState({
                loaded: true,
                properties: response.data.data || [],
            });
        })
        .catch(_error => {
            this.setState({ loaded: true });
            Helpers.showError("Neizdevās ielādēt braucienus!");
        })

    searchProperty = (keyword) => {
        this.setState({
            searchKeyword: keyword.target.value,
        });
    }

    filterProperties = () => this.state.searchKeyword.length > 0 ? this.state.properties.filter(this.filterCallback) : this.state.properties;

    formatPropertyName = (property) => {
        if (property.code) {
            return `${property.code} (${property.name}) - ${property.client} (${property.ppr_serial_number})`;
        }

        return `${property.name} - ${property.client} (${property.ppr_serial_number})`;
    }

    filterCallback = property =>
        Helpers.toLowercase(property.name).indexOf(this.state.searchKeyword.toLowerCase()) > -1 ||
        Helpers.toLowercase(property.client).indexOf(this.state.searchKeyword.toLowerCase()) > -1

    getPropertyUrl = (property) => {
        if (property.state === JOB_TYPES.AKTIVS_BRAUCIENS) {
            return `/brauciens/${property.id}`;
        }

        return `/brauciens/${property.id}/pienemt`;
    }

    render() {
        const navLinkMobile = { style: { fontSize: 22 }};
        const navLinkDesktop = { style: { fontSize: 16 }};

        const classNameByState = (state) => {
            if (state === JOB_TYPES.PIESKIRTS_UZDEVUMS) {
                return "jow_job_item";
            }

            return "active_job_item";
        };

        const itemByState = (property) =>
            <NavLink to={this.getPropertyUrl(property)} className="segment-with-navlink no-hover">
                <div className="propery-list-item">
                    <div className="item">
                        <p className="item-state">{JOB_TYPE_LABEL[property.state]}</p>
                        <h4 className="item-header">{this.formatPropertyName(property)}</h4>
                    </div>
                </div>
            </NavLink>;

        const renderProperties = () => {
            if (this.filterProperties().length === 0) {
                return <Message
                    error
                    header="Netika atrasts neviens brauciens!"
                    list={[
                        "Pārliecinaties, vai atlēgvārds ir ievadīts pareizi",
                    ]}
                />;
            }

            return <div>
                <Responsive maxWidth={Responsive.onlyMobile.maxWidth} className="full--width">
                    <h2 style={{ marginBottom: 15, marginTop: 20 }}>Aktīvie braucieni</h2>
                </Responsive>

                <ResponsiveElement desktopProps={{ as: Segment.Group }}>
                    <div>
                        {this.filterProperties().map(property => (
                            <Segment key={property.id} style={{ padding: 0 }} className={classNameByState(property.state)}>
                                <ResponsiveElement desktopProps={navLinkDesktop} mobileProps={navLinkMobile}>
                                    {itemByState(property)}
                                </ResponsiveElement>
                            </Segment>
                        ))}
                    </div>
                </ResponsiveElement>
            </div>;
        };

        return (
            <div>
                <header style={{ background: "#fff" }}>
                    <div className="layout-wrapper">
                        <HeaderMenu { ...this.props }/>

                        <Responsive as={Grid}>
                            <Grid.Row style={{ paddingTop: 40, paddingBottom: 0 }}>
                                <Grid.Column mobile={16} computer={10}>
                                    <Header as="h1">Aktīvie braucieni</Header>
                                </Grid.Column>
                                <Grid.Column mobile={13} computer={5}>
                                    <ResponsiveElement mobileProps={{ size: "huge" }}>
                                        <Input fluid icon="search" placeholder="Meklēt braucienu" onChange={this.searchProperty}/>
                                    </ResponsiveElement>
                                </Grid.Column>
                                <Grid.Column mobile={3} computer={1}>
                                    <ResponsiveElement mobileProps={{ size: "huge", style: { marginLeft: 6 } }} desktopProps={{ style: { marginLeft: 15, width: "77%" } }}>
                                        <Button onClick={this.refreshProperties} icon={true}>
                                            <Icon name="refresh"/>
                                        </Button>
                                    </ResponsiveElement>
                                </Grid.Column>
                            </Grid.Row>
                        </Responsive>
                    </div>
                </header>

                <div className="layout-wrapper">
                    {!this.state.loaded ? <Loader active size="large" inline="centered">Lūdzu uzgaidiet</Loader> : renderProperties()}
                </div>
            </div>
        );
    }
}

export const Properties = withRouter(PropertiesBase as any);
