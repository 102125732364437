export const stackingTypes = [
    {
        label: "10 (PZBA) [vecais: 1]",
        id: "pzba",
    },
    {
        label: "11 (PZB28) [vecais: 2]",
        id: "pzb_resnie",
    },
    {
        label: "12 (PZB18) [vecais: 3]",
        id: "pzb_videjie",
    },
    {
        label: "13 (PZB14) [vecais: 4]",
        id: "pzb_tievie",
    },
    {
        label: "14 (PZB10)",
        id: "pzb10",
    },
    {
        label: "15 (PZBT)",
        id: "pzbt",
    },
    {
        label: "16 (PZB6)",
        id: "pzb6",
    },
    {
        label: "17 (PZB60)",
        id: "pzb60",
    },
    {
        label: "20 (EZBA) [vecais: 5]",
        id: "ezba",
    },
    {
        label: "21 (EZB28) [vecais: 6]",
        id: "ezb_resnie",
    },
    {
        label: "22 (EZB18) [vecais: 7]",
        id: "ezb_videjie",
    },
    {
        label: "23 (EZB14) [vecais: 8]",
        id: "ezb_tievie",
    },
    {
        label: "24 (EZB10)",
        id: "ezb10",
    },
    {
        label: "25 (EZBS)",
        id: "ezbs",
    },
    {
        label: "26 (EZB6)",
        id: "ezb6",
    },
    {
        label: "27 (EZB60)",
        id: "ezb60",
    },
    {
        label: "30 (BF) [vecais: 9]",
        id: "bf",
    },
    {
        label: "31 (BFC) [vecais: 19]",
        id: "bzb",
    },
    {
        label: "32 (BF3)",
        id: "bf3",
    },
    {
        label: "33 (BF4)",
        id: "bf4",
    },
    {
        label: "40 (LKT) [vecais: 10]",
        id: "lkt",
    },
    {
        label: "41 (LKT14)",
        id: "lkt14",
    },
    {
        label: "42 (LKT22)",
        id: "lkt22",
    },
    {
        label: "43 (LKTB)",
        id: "lktb",
    },
    {
        label: "44 (AZB) [vecais: 11]",
        id: "azb",
    },
    {
        label: "45 (MZB) [vecais: 12]",
        id: "mzb",
    },
    {
        label: "50 (SKT) [vecais: 13]",
        id: "skt",
    },
    {
        label: "51 (SKG) [vecais: 14]",
        id: "skg",
    },
    {
        label: "52 (SKGP)",
        id: "skgp",
    },
    {
        label: "53 (SKGE)",
        id: "skge",
    },
    {
        label: "54 (SKGPS)",
        id: "skgps",
    },
    {
        label: "55 (SKGES)",
        id: "skges",
    },
    {
        label: "60 (SKPM) [vecais: 15]",
        id: "skpm",
    },
    {
        label: "61 (BPM) [vecais: 16]",
        id: "bpm",
    },
    {
        label: "62 (APM) [vecais: 17]",
        id: "apm",
    },
    {
        label: "70 (M) [vecais: 18]",
        id: "m",
    },
    {
        label: "71 (SKM)",
        id: "skm",
    },
    {
        label: "72 (LKM)",
        id: "lkm",
    },
    {
        label: "73 (AM)",
        id: "am",
    },
    {
        label: "80 (OZB) [vecais: 20]",
        id: "ozb",
    },
    {
        label: "81 (OSZB)",
        id: "oszb",
    },
    {
        label: "82 (OZM)",
        id: "ozm",
    },
    {
        label: "83 (OSM)",
        id: "osm",
    },
    {
        label: "90 (NS) [vecais: 21]",
        id: "ns",
    },
];

export const JOB_TYPES = {
    PIESKIRTS_UZDEVUMS: "PIESKIRTS_UZDEVUMS",
    AKTIVS_BRAUCIENS: "AKTIVS_BRAUCIENS",
    NOSLEGTS: "NOSLEGTS",
};

export const JOB_TYPE_LABEL = {
    PIESKIRTS_UZDEVUMS: "Piešķirts uzdevums",
    AKTIVS_BRAUCIENS: "Aktīvs brauciens",
    NOSLEGTS: "Noslēgts",
};
