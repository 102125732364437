import axios from "axios";
import { createForm } from "rc-form";
import * as React from "react";
import { NavLink, Redirect } from "react-router-dom";
import { Button, Form, Modal } from "semantic-ui-react";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive/Responsive";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon/Icon";

import { HeaderMenu } from "../../components/HeaderMenu";
import { LayoutProps } from "../../components/LayoutWrapper";
import { ResponsiveElement } from "../../components/ResponsiveElement";
import { SelectField } from "../../components/SelectD";
import { stackingTypes } from "../../utils/constants";
import { Helpers } from "../../utils/helpers";

interface JobState {
    loaded: boolean;
    data: any;
    redirectToHomepage: boolean;
    finishConfirmVisible: boolean;
    starting: boolean;
}

interface JobProps extends LayoutProps {
    match: any;
    form: any;
}

class FinishJobWrapper extends React.Component<JobProps, JobState> {
    constructor(props) {
        super(props);

        this.state = {
            finishConfirmVisible: false,
            redirectToHomepage: false,
            loaded: false,
            data: null,
            starting: false,
        };
    }

    componentDidMount() {
        this.fetchPropertyData();
    }

    fetchPropertyData = () =>
        axios.get(`/api/job/${this.props.match.params.jobId}`, {
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })
        .then(response => {
            this.setState({
                loaded: true,
                data: response.data.data || null,
            });
        })
        .catch(_error => {
            this.setState({ loaded: true });
            Helpers.showError("Neizdevās ielādēt brauciena datus!");
        })

    openConfirm = () => {
        this.props.form.validateFields({ force: true }, async (error, values) => {
            if (error) {
                return;
            }

            this.setState({ finishConfirmVisible: true });
        });
    }

    closeConfirm = () => this.setState({ finishConfirmVisible: false });

    finishJobWithProperty = () => {
        this.props.form.validateFields({ force: true }, async (error, values) => {
            if (error) {
                this.closeConfirm();
                return;
            }

            this.setState({ starting: true }, async () => {
                const response = await axios.put(`/api/job/${this.props.match.params.jobId}/finish`, {
                    ...values,
                }, {
                    headers: {
                        Authorization: Helpers.getSessionId(),
                    },
                });

                if (response.data.success === false) {
                    Helpers.showError("Kaut kas nogāja greizi!");
                    return;
                }

                this.setState({ redirectToHomepage: true });
            });
        });
    }

    openEpvz = () => {
        // Open file in new tab
        if(this.state.data.epvz == null) {
            this.fetchPropertyData();
            Helpers.showError("EPVZ vēl nav saņemts!");
            return;
        }
        window.open(this.state.data.epvz, "_newtab");
    }

    render() {
        if (this.state.redirectToHomepage) {
            return <Redirect to="/"/>;
        }

        const { data } = this.state;
        const { getFieldProps, getFieldError, getFieldValue } = this.props.form;

        const navLinkMobile = { style: { fontSize: 22, width: "100%" }};
        const navLinkDesktop = { style: { fontSize: 16 }};

        const renderMenu = () =>
            <Form>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <Form.Field className="full--width">
                                <label>Nobrauktie kilometri <span style={{ fontWeight: 300 }}>(Obligāti aizpildāms)</span></label>

                                <input
                                    className={!!getFieldError("distance") ? "error-input" : null}
                                    type="number"
                                    pattern="[0-9]*"
                                    {...getFieldProps("distance", {
                                        rules: [{
                                            required: true,
                                        }],
                                    })}
                                />

                                <div>
                                    {!!getFieldError("distance") && <span className="error-text">Lūdzu aizpildiet šo lauku!</span>}
                                </div>
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column>
                            <Form.Field className="full--width">
                                <label>Komentārs (Maksimālais zīmju skaits: 100)</label>

                                <textarea
                                    maxlength="100"
                                    {...getFieldProps("comment")}
                                />
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column>
                            <ResponsiveElement desktopProps={navLinkDesktop} mobileProps={navLinkMobile}>
                                <Button positive={true} onClick={this.openEpvz}>
                                    <Icon name="check"/> Skatīt EPVZ
                                </Button>
                            </ResponsiveElement>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column>
                            <ResponsiveElement desktopProps={navLinkDesktop} mobileProps={navLinkMobile}>
                                <Button positive={true} onClick={this.openConfirm}>
                                    <Icon name="check"/> Pabeigt braucienu
                                </Button>
                            </ResponsiveElement>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>;

        const backButton = <NavLink to={"/"} className="ui icon left labeled button huge" style={{ color: "#fff" }}>
            <Icon name="angle left"/> Visi braucieni
        </NavLink>;

        return (
            <div>
                <Modal open={this.state.finishConfirmVisible} size="mini" className="confirm--modal">
                    <Modal.Content>
                        <p>
                            Vai Jūs tiešam vēlaties pabeigt braucienu?
                        </p>
                    </Modal.Content>
                    <Modal.Actions>
                        {!this.state.starting && <Button color="red" onClick={this.closeConfirm}>
                            Atcelt
                        </Button>}
                        <Button color="green" onClick={this.finishJobWithProperty} disabled={this.state.starting}>
                            {this.state.starting ? "Lūdzu uzgaidiet" : "Pabeigt braucienu"}
                        </Button>
                    </Modal.Actions>
                </Modal>

                <header style={{ background: "#fff" }}>
                    <div className="layout-wrapper">
                        <HeaderMenu { ...this.props } mobileLogoReplace={backButton}/>

                        <Responsive as={Grid}>
                            <Grid.Row style={{ paddingTop: 20, paddingBottom: 0 }}>
                                <Responsive maxWidth={Responsive.onlyMobile.maxWidth} mobile={16} computer={16} textAlign="center" as={Grid.Column}>
                                    <Header as="h1" style={{ margin: "40px 0 15px 0" }}>
                                        {data && data.name}
                                    </Header>
                                </Responsive>

                                <Responsive minWidth={Responsive.onlyTablet.minWidth} mobile={8} computer={8} textAlign="left" as={Grid.Column}>
                                    <Header as="h1">
                                        {data && data.name}
                                        <Header.Subheader>
                                            <NavLink to={"/"} style={{ color: "#00000099" }}>
                                                <Icon name="angle left" style={{ margin: 0 }}/> Visi braucieni
                                            </NavLink>
                                        </Header.Subheader>
                                    </Header>
                                </Responsive>
                            </Grid.Row>
                        </Responsive>
                    </div>
                </header>

                <div className="layout-wrapper">
                    {renderMenu()}
                </div>
            </div>
        );
    }
}

export const FinishJob = createForm()(FinishJobWrapper as any);
