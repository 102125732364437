import * as React from "react";

interface SwitchSelectProps {
    onChange?(value): void;
    value?: any;
    options?: Option[];
}

interface Option {
    id: string | boolean | number;
    label: string;
}

export class SelectField extends React.Component<SwitchSelectProps, {}> {
    render() {
        return (
            <select onChange={this.props.onChange} style={{ width: "100%" }} {...this.props}>
                {this.props.options && this.props.options.map(option => (
                    <option key={option.id as any} value={option.id as any} { ...this.props.value === option.id ? { selected: true } : {}}>{option.label}</option>
                ))}
            </select>
        );
    }
}
