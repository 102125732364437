import axios from "axios";
import { createForm } from "rc-form";
import * as React from "react";
import { NavLink, withRouter } from "react-router-dom";
import { Button, Form } from "semantic-ui-react";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive/Responsive";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon/Icon";

import { HeaderMenu } from "../../components/HeaderMenu";
import { LayoutProps } from "../../components/LayoutWrapper";
import { ResponsiveElement } from "../../components/ResponsiveElement";
import { Helpers } from "../../utils/helpers";

interface JobState {
    loaded: boolean;
    data: any;
    finishing: boolean;
    acceptInProgress: boolean;
}

interface JobProps extends LayoutProps {
    match: any;
    form: any;
}

class AcceptJobWrapper extends React.Component<JobProps, JobState> {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            data: null,
            finishing: false,
            acceptInProgress: false,
        };
    }

    componentDidMount() {
        this.fetchPropertyData();
    }

    fetchPropertyData = () =>
        axios.get(`/api/job/${this.props.match.params.jobId}`, {
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })
        .then(response => {
            this.setState({
                loaded: true,
                data: response.data.data || null,
            });
        })
        .catch(_error => {
            this.setState({ loaded: true });
            Helpers.showError("Neizdevās ielādēt brauciena datus!");
        })

    acceptTrip = () => {
        this.setState({ acceptInProgress: true }, () => {
            axios.post(`/api/job/${this.props.match.params.jobId}/acceptTrip`, {}, {
                headers: {
                    Authorization: Helpers.getSessionId(),
                },
            })
            .then(response => {
                if (!response.data.success) {
                    throw new Error("Response success is equal to false");
                }

                this.setState({
                    acceptInProgress: false,
                });

                // Redirect to active page
                this.props.history.push(`/brauciens/${this.props.match.params.jobId}/sakt`);
            })
            .catch(_error => {
                this.setState({ acceptInProgress: false });
                Helpers.showError("Neizdevās pieņemt braucienu!");
            });
        });
    }

    formatPageHeader = (data) => {
        if (!data) {
            return null;
        }

        let headerString = `${data.ppr_serial_number} ${data.code} `;

        if (data.name) {
            headerString += `(${data.name})`;
        }

        headerString += ` → ${data.client}`;

        return headerString;
    }

    render() {
        const { data } = this.state;
        const navLinkMobile = { style: { fontSize: 22, width: "100%" }};
        const navLinkDesktop = { style: { fontSize: 16 }};

        const renderMenu = () =>
            <Form>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <Form.Field className="full--width">
                                <label>Sortiments</label>
                                <input readOnly value={this.state.data && this.state.data.included_stackings}/>
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column>
                            <Form.Field className="full--width">
                                <label>Dalīta krava</label>
                                <input readOnly value={this.state.data && !!this.state.data.separated_cargo ? "Jā" : "Nē"}/>
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column>
                            <Form.Field className="full--width">
                                <label>Loģistikas komentārs</label>
                                <textarea readOnly value={this.state.data && this.state.data.manager_notes}></textarea>
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column>
                            <Form.Field className="full--width">
                                <label>Krautuves vieta kartē</label>
                                <Button>
                                    Skatīt attēlu
                                </Button>
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column>
                            <Form.Field className="full--width">
                                <label>Piebraukšanas vieta</label>
                                <Button>
                                    Skatīt attēlu
                                </Button>
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column>
                            <Form.Field className="full--width">
                                <label>Meistara komentārs</label>
                                <textarea readOnly></textarea>
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column>
                            <Form.Field className="full--width">
                                <label>Pievešanas termiņš</label>
                                <input readOnly/>
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column>
                            <Form.Field className="full--width">
                                <label>Ciršanas apliecinājums</label>
                                <Button>
                                    Lejupielādēt
                                </Button>
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column>
                            <ResponsiveElement desktopProps={navLinkDesktop} mobileProps={navLinkMobile}>
                                <Button positive={true} onClick={this.acceptTrip} disabled={this.state.acceptInProgress} loading={this.state.acceptInProgress}>
                                    <Icon name="check"/> Pieņemt braucienu!
                                </Button>
                            </ResponsiveElement>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>;

        const backButton = <NavLink to={"/"} className="ui icon left labeled button huge" style={{ color: "#fff" }}>
            <Icon name="angle left"/> Visi braucieni
        </NavLink>;

        return (
            <div>
                <header style={{ background: "#fff" }}>
                    <div className="layout-wrapper">
                        <HeaderMenu { ...this.props } mobileLogoReplace={backButton}/>

                        <Responsive as={Grid}>
                            <Grid.Row style={{ paddingTop: 20, paddingBottom: 0 }}>
                                <Responsive maxWidth={Responsive.onlyMobile.maxWidth} mobile={16} computer={16} textAlign="center" as={Grid.Column}>
                                    <Header as="h3" style={{ margin: "40px 0 15px 0" }}>
                                        {this.formatPageHeader(data)}
                                        {data && data.comment && <p>{data.comment}</p>}
                                    </Header>
                                </Responsive>

                                <Responsive minWidth={Responsive.onlyTablet.minWidth} mobile={8} computer={8} textAlign="left" as={Grid.Column}>
                                    <Header as="h2">
                                        {this.formatPageHeader(data)}
                                        {data && data.comment && <p>{data.comment}</p>}

                                        <Header.Subheader>
                                            <NavLink to={"/"} style={{ color: "#00000099" }}>
                                                <Icon name="angle left" style={{ margin: 0 }}/> Visi braucieni
                                            </NavLink>
                                        </Header.Subheader>
                                    </Header>
                                </Responsive>
                            </Grid.Row>
                        </Responsive>
                    </div>
                </header>

                <div className="layout-wrapper">
                    {renderMenu()}
                </div>
            </div>
        );
    }
}

export const AcceptJob = withRouter(createForm()(AcceptJobWrapper as any));
