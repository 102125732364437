import * as React from "react";

interface ErrorBoundryState {
    error: any;
    errorInfo: any;
}

export class ErrorBoundary extends React.Component<{}, ErrorBoundryState> {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            errorInfo: null,
        };
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ error, errorInfo: info });
    }

    render() {
        if (this.state.errorInfo) {

            return <div style={{ margin: 20 }}>
                <h1>Kaut kas nogāja greizi!</h1>;

                {process.env.debug && <details style={{ whiteSpace: "pre-wrap" }}>
                    {this.state.error && this.state.error.toString()}
                    <br />
                    {this.state.errorInfo.componentStack}
                </details>}
            </div>;
        }

        return this.props.children;
    }
}
