import React from "react";

export const FatalError = () => (
    <div style={{
        margin: "0 auto",
        width: "100%",
        textAlign: "center",
    }}>
        <h1 style={{ fontSize: "7em" }}>500</h1>
        <h2 style={{ fontSize: "5em" }}>internal server error</h2>
    </div>
);
