import axios from "axios";
import { createForm } from "rc-form";
import * as React from "react";
import { NavLink, Redirect } from "react-router-dom";
import { Button, Form, Modal } from "semantic-ui-react";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive/Responsive";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon/Icon";

import { HeaderMenu } from "../../components/HeaderMenu";
import { LayoutProps } from "../../components/LayoutWrapper";
import { ResponsiveElement } from "../../components/ResponsiveElement";
import { SelectField } from "../../components/SelectD";
// import { stackingTypes } from "../../utils/constants";
import { Helpers } from "../../utils/helpers";

interface JobState {
    loaded: boolean;
    data: any;
    finishConfirmVisible: boolean;
    starting: boolean;
    stackingTypes: stackingType[],
}

interface JobProps extends LayoutProps {
    match: any;
    form: any;
}

interface stackingType {
    label: string;
    id: string;
}

class StartJobWrapper extends React.Component<JobProps, JobState> {
    constructor(props) {
        super(props);

        this.state = {
            finishConfirmVisible: false,
            loaded: false,
            data: null,
            starting: false,
            stackingTypes: []
        };
    }

    componentDidMount() {
        this.fetchPropertyData();
        this.fetchStackingTypes();
    }

    fetchPropertyData = () =>
        axios.get(`/api/job/${this.props.match.params.jobId}`, {
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })
        .then(response => {
            this.setState({
                loaded: true,
                data: response.data.data || null,
            });

        })
        .catch(_error => {
            this.setState({ loaded: true });
            Helpers.showError("Neizdevās ielādēt brauciena datus!");
        })

    fetchStackingTypes = () =>
        axios.get(`/api/job/stackingTypes/${this.props.match.params.jobId}`, {
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })
        .then(response => {
            console.log("stacking types res: ", response);
            this.setState({
                stackingTypes: response.data.data.rows || [],
            });
            console.log("state ", this.state.stackingTypes);

        })
        .catch(_error => {
            this.setState({ stackingTypes: [] });
            Helpers.showError("Neizdevās ielādēt Stacking Types!");
        })

    openConfirm = () => {
        this.props.form.validateFields({ force: true }, async (error, values) => {
            if (error) {
                return;
            }

            this.setState({ finishConfirmVisible: true });
        });
    }

    closeConfirm = () => this.setState({ finishConfirmVisible: false });

    finishJobWithProperty = () => {
        this.props.form.validateFields({ force: true }, async (error, values) => {
            if (error) {
                this.closeConfirm();
                return;
            }

            this.setState({ starting: true }, async () => {
                const response = await axios.put(`/api/job/${this.props.match.params.jobId}/startTrip`, {
                    ...values,
                }, {
                    headers: {
                        Authorization: Helpers.getSessionId(),
                    },
                });

                if (response.data.success === false) {
                    Helpers.showError("Kaut kas nogāja greizi!");
                    return;
                }

                // Open file in new tab
                window.open(response.data.fileUrl, "_newtab");

                // Redirect to active page
                 this.props.history.push(`/brauciens/${this.props.match.params.jobId}`);
            });
        });
    }

    render() {
        const { data } = this.state;
        const { getFieldProps, getFieldError, getFieldValue } = this.props.form;

        const navLinkMobile = { style: { fontSize: 22, width: "100%" }};
        const navLinkDesktop = { style: { fontSize: 16 }};
        const showConfirmationField = data && (data.name === "Laukums: Astes" || data.name === "Laukums: Rezekne" || data.name === "Laukums: Rēzekne");

        const renderMenu = () =>
            <Form>
                <Grid>
                    <Grid.Row columns={3}>
                        <Grid.Column>
                            <h3>Sortiments</h3>
                        </Grid.Column>
                        <Grid.Column>
                            <h3>Kubikmetri</h3>
                        </Grid.Column>
                        <Grid.Column style={{ paddingLeft: 20 }}>
                            <h3>Atlikums krautuvēs</h3>
                        </Grid.Column>
                    </Grid.Row>
                    {[0, 1, 2, 3, 4, 5].map(index => (
                        <Grid.Row columns={3}>
                            <Grid.Column  style={{ paddingRight: 20 }}>
                                <SelectField
                                    className={!!getFieldError(`assortment[${index}]`) ? "error-input" : null}
                                    options={[{ label: "", id: ""}].concat(this.state.stackingTypes)}
                                    {...getFieldProps(`assortment[${index}]`, {
                                        rules: [{
                                            required: index === 0,
                                            message: "Lūdzu aizpildiet šo lauku!",
                                        }],
                                    })}
                                />

                                <div>
                                    {!!getFieldError(`assortment[${index}]`) && <span className="error-text">
                                        {getFieldError(`assortment[${index}]`)}
                                    </span>}
                                </div>
                            </Grid.Column>
                            <Grid.Column>
                                <input
                                    className={!!getFieldError(`cubicmeter[${index}]`) ? "error-input" : null}
                                    type="number"
                                    pattern="[0-9]*"
                                    {...getFieldProps(`cubicmeter[${index}]`, {
                                        rules: [{
                                            required: getFieldValue(`assortment[${index}]`),
                                            message: "Lūdzu aizpildiet šo lauku!",
                                        }, {
                                            max: 50,
                                            type: "number",
                                            message: "Vērtība jābūt mazākai par 50!",
                                            transform: Helpers.toNumber,
                                        }],
                                    })}
                                />
                                <div>
                                    {!!getFieldError(`cubicmeter[${index}]`) && <span className="error-text">
                                        {getFieldError(`cubicmeter[${index}]`)}
                                    </span>}
                                </div>
                            </Grid.Column>
                            <Grid.Column style={{ paddingLeft: 20 }}>
                                <input
                                    type="number"
                                    pattern="[0-9]*"
                                    {...getFieldProps(`residue[${index}]`)}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    ))}

                    {showConfirmationField && <Grid.Row>
                        <Grid.Column>
                            <Form.Field className="full--width">
                                <label>Ciršanas apliecinājums</label>
                                <input
                                    type="number"
                                    pattern="[0-9]*"
                                    {...getFieldProps("confirmation")}
                                />
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>}

                    <Grid.Row>
                        <Grid.Column>
                            <ResponsiveElement desktopProps={navLinkDesktop} mobileProps={navLinkMobile}>
                                <Button positive={true} onClick={this.openConfirm}>
                                    <Icon name="check"/> Sākt braucienu
                                </Button>
                            </ResponsiveElement>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>;

        const backButton = <NavLink to={"/"} className="ui icon left labeled button huge" style={{ color: "#fff" }}>
            <Icon name="angle left"/> Visi braucieni
        </NavLink>;

        return (
            <div>
                <Modal open={this.state.finishConfirmVisible} size="mini" className="confirm--modal">
                    <Modal.Content>
                        <p>
                            Vai Jūs tiešam vēlaties sākt braucienu?
                        </p>
                    </Modal.Content>
                    <Modal.Actions>
                        {!this.state.starting && <Button color="red" onClick={this.closeConfirm}>
                            Atcelt
                        </Button>}
                        <Button color="green" onClick={this.finishJobWithProperty} disabled={this.state.starting}>
                            {this.state.starting ? "Lūdzu uzgaidiet" : "Sākt braucienu"}
                        </Button>
                    </Modal.Actions>
                </Modal>

                <header style={{ background: "#fff" }}>
                    <div className="layout-wrapper">
                        <HeaderMenu { ...this.props } mobileLogoReplace={backButton}/>

                        <Responsive as={Grid}>
                            <Grid.Row style={{ paddingTop: 20, paddingBottom: 0 }}>
                                <Responsive maxWidth={Responsive.onlyMobile.maxWidth} mobile={16} computer={16} textAlign="center" as={Grid.Column}>
                                    <Header as="h1" style={{ margin: "40px 0 15px 0" }}>
                                        {data && data.name}
                                    </Header>
                                </Responsive>

                                <Responsive minWidth={Responsive.onlyTablet.minWidth} mobile={8} computer={8} textAlign="left" as={Grid.Column}>
                                    <Header as="h1">
                                        {data && data.name}
                                        <Header.Subheader>
                                            <NavLink to={"/"} style={{ color: "#00000099" }}>
                                                <Icon name="angle left" style={{ margin: 0 }}/> Visi braucieni
                                            </NavLink>
                                        </Header.Subheader>
                                    </Header>
                                </Responsive>
                            </Grid.Row>
                        </Responsive>
                    </div>
                </header>

                <div className="layout-wrapper">
                    {renderMenu()}
                </div>
            </div>
        );
    }
}

export const StartJob = createForm()(StartJobWrapper as any);
