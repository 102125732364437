import { LayoutProps } from "../../components/LayoutWrapper";
import * as React from 'react';
import { HeaderMenu } from "../../components/HeaderMenu";
import { Button, Grid, Header, Icon, Input, Loader, Message, Modal, Responsive, Segment, Table } from "semantic-ui-react";
import { ResponsiveElement } from "../../components/ResponsiveElement";
import { Helpers } from "../../utils/helpers";
import axios from 'axios';
import { Link } from "react-router-dom";
import { userInfo } from "os";

interface UserListItem {
    id: number;
    first_name: string;
    last_name: string;
    username: string;
    email: string;
    admin: boolean;
    car_number: string;
}

interface UserListState {
    users: UserListItem[];
    loaded: boolean;
    searchKeyword: string;
}

interface UserListProps extends LayoutProps{
}

export class UserList extends React.Component<UserListProps, UserListState> {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            loaded: false,
            searchKeyword: ''
        }
    }

    fetchUsers = () =>
        axios.get(`/api/users`, {
            headers: {
                Authorization: Helpers.getSessionId(),
                ActiveType: this.props.getActiveType(),
            },
        })
        .then(response => {
            this.setState({
                loaded: true,
                users: response.data.data || [],
            });
        })
        .catch(_error => {
            this.setState({ loaded: true });
            Helpers.showError("Neizdevās ielādēt lietotājus!");
        })

    componentDidMount() {
        this.fetchUsers();
    }

    filterUsers = () => this.state.searchKeyword.length > 0 ? this.state.users.filter(this.filterCallback) : this.state.users;

    filterCallback = (user: UserListItem) =>
        Helpers.toLowercase(user.first_name + ' ' + user.last_name).indexOf(this.state.searchKeyword.toLowerCase()) > -1 ||
        Helpers.toLowercase(user.username).indexOf(this.state.searchKeyword.toLowerCase()) > -1 ||
        Helpers.toLowercase(user.email).indexOf(this.state.searchKeyword.toLowerCase()) > -1;

    deleteUser = async (userId: number) => {
        this.setState({
            loaded: false
        });

        try{
            await axios.delete(`/users/${userId}`, {
                headers: { Authorization: Helpers.getSessionId() }
            });
    
            Helpers.showSuccess('Lietotājs veiksmīgi izdzēsts!');
        }catch(e){
            Helpers.showError('Kaut kas nogāja greizi!');
        }

        await this.fetchUsers();
    }

    refreshUsers = () => {
        this.setState({ loaded: false }, () => {
            this.fetchUsers();
        });
    }

    searchUsers = (keyword) => {
        this.setState({
            searchKeyword: keyword.target.value,
        });
    }

    render() {
        const deleteButton = (user: UserListItem): JSX.Element =>
            <Modal
                trigger={<Icon link name='trash alternate outline' color="red"/>}
                header="Uzmanību!"
                content={`Vai patiešām vēlaties dzēst lietotāju "${user.username}"?`}
                actions={['Nē!', { key: 'done', content: 'Dzēst lietotāju!', positive: false, onClick: () => this.deleteUser(user.id) }]}
            >
                
            </Modal>
        


        const renderUsers = () => {
            if (this.filterUsers().length === 0) {
                return <Message
                    error
                    header="Netika atrasts neviens lietotājs!"
                    list={[
                        "Pārliecinaties, vai atlēgvārds ir ievadīts pareizi",
                    ]}
                />;
            }

            return <div>
                <Responsive maxWidth={Responsive.onlyMobile.maxWidth} className="full--width">
                    <h2 style={{ marginBottom: 15, marginTop: 20 }}>Lietotāji</h2>
                </Responsive>

                <Table celled size='large' unstackable>
                    <Table.Header>
                        <Table.HeaderCell>Vārds</Table.HeaderCell>
                        <Table.HeaderCell>Uzvārds</Table.HeaderCell>
                        <Table.HeaderCell>Epasts</Table.HeaderCell>
                        <Table.HeaderCell>Lietotājvārds</Table.HeaderCell>
                        <Table.HeaderCell>Sastāva kods</Table.HeaderCell>
                        <Table.HeaderCell>Darbības</Table.HeaderCell>
                    </Table.Header>
                    <Table.Body>
                        {this.filterUsers().map(user => (
                            <Table.Row key={user.id} style={{ padding: 0 }}>
                                <Table.Cell>{user.first_name}</Table.Cell>
                                <Table.Cell>{user.last_name}</Table.Cell>
                                <Table.Cell>{user.email}</Table.Cell>
                                <Table.Cell>{user.username}</Table.Cell>
                                <Table.Cell>{user.car_number}</Table.Cell>
                                <Table.Cell>
                                    <Link to={`/users/${user.id}`}><Icon link name='edit outline' /></Link>
                                    {deleteButton(user)}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                    
                </Table>
            </div>;
        };
        return (
            <div>
                <header style={{ background: "#fff" }}>
                    <div className="layout-wrapper">
                        <HeaderMenu { ...this.props }/>

                        <Responsive as={Grid}>
                            <Grid.Row style={{ paddingTop: 40, paddingBottom: 0 }}>
                                <Grid.Column mobile={16} computer={10}>
                                    <Header as="h1">Lietotāji</Header>
                                </Grid.Column>
                                <Grid.Column mobile={13} computer={5}>
                                    <ResponsiveElement mobileProps={{ size: "huge" }}>
                                        <Input fluid icon="search" placeholder="Meklēt lietotājus" onChange={this.searchUsers}/>
                                    </ResponsiveElement>
                                </Grid.Column>
                                <Grid.Column mobile={3} computer={1}>
                                    <ResponsiveElement mobileProps={{ size: "huge", style: { marginLeft: 6 } }} desktopProps={{ style: { marginLeft: 15, width: "77%" } }}>
                                        <Button icon={true} onClick={this.refreshUsers}>
                                            <Icon name="refresh"/>
                                        </Button>
                                    </ResponsiveElement>
                                </Grid.Column>
                            </Grid.Row>
                        </Responsive>
                    </div>
                </header>

                <div className="layout-wrapper">
                    <Button as={Link} to="/users/create" icon labelPosition='right' style={{marginBottom: '15px'}}>
                        Izveidot jaunu lietotāju
                        <Icon name='plus' />
                    </Button>
                    {!this.state.loaded ? <Loader active size="large" inline="centered">Lūdzu uzgaidiet</Loader> : renderUsers()}
                </div>
            </div>
        )
    }
}

