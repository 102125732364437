import { LayoutProps } from "../../components/LayoutWrapper";
import * as React from 'react';
import { HeaderMenu } from "../../components/HeaderMenu";
import { Button, Dimmer, Form, Grid, Header, Icon, Input, Loader, Message, Responsive, Segment, Table } from "semantic-ui-react";
import { ResponsiveElement } from "../../components/ResponsiveElement";
import { Helpers } from "../../utils/helpers";
import axios from 'axios';
import { UserInfo, UserInfoForm } from "../../components/UserInfo";
import { groupBy, mapValues, values, flatten } from 'lodash';
import { createForm, formShape } from 'rc-form';


interface ChangePasswordState {
    loading: boolean;
    serverErrors: {[field: string]: string[]};
}

interface ChangePasswordProps extends LayoutProps{
    form: any;
    userId: number;
}

class ChangePasswordWrapper extends React.Component<ChangePasswordProps, ChangePasswordState> {
    constructor(props) {
        super(props);
        console.log(props);
        this.state = {
            loading: false,
            serverErrors: {},
        };
    }

    onValueChanged = (field) => {
        const errors = this.state.serverErrors;
        errors[field] = [];
        this.setState({
            serverErrors: errors
        });
    }

    fieldErrors = (field: string): string[] | undefined => {
        const { getFieldError } = this.props.form;
        const errors = (getFieldError(field) || []).concat(this.state.serverErrors[field] || []);
        if(errors.length === 0) return undefined;
        return errors;
    }

    formHasErrors = (): boolean => {
        return values(this.props.form.getFieldsError()).filter(errors => !!errors).concat(flatten(values(this.state.serverErrors))).length > 0;
    }
    
    onSubmit = async (e) => {        
        e.preventDefault();
        this.setState({
            loading: true
        });
        console.log(this);
        let formData;
        //validate
        try{
            formData = await new Promise((resolve, reject) => this.props.form.validateFields((err, values) => { if(err){ reject(err) } else { resolve(values) }})) as any;
            if(flatten(values(this.state.serverErrors)).length > 0){
                throw new Error('Server side errors not fixed!');
            }
        }catch(e){
            console.log(e)
            this.setState({
                loading: false
            })
            return;
        }

        try {
            const { data } = await axios.patch(`/api/users/${this.props.userId}/change-password`, {
                old_password: formData.old_password,
                new_password: formData.new_password
            }, { headers: {
                Authorization: Helpers.getSessionId()
            }});
            Helpers.showSuccess('Parole veiksmīgi nomainīta!');
            this.props.history.push('/');
            
        }catch(error){
            this.setState({
                loading: false
            });
            if(error.response && error.response.status === 400){
                const errors = error.response.data.errors;
                this.setState({
                    serverErrors: mapValues(groupBy(errors,error => error.param), errors => errors.map(error => error.msg))
                })
            }else{
                Helpers.showError("Kaut kas nogāja greizi!");
            }
        }
        this.setState({
            loading: false
        });
    }

    render() {
        const { getFieldProps } = this.props.form;

        const existingPasswordInput = {
            ...getFieldProps("old_password", {
                rules: [{
                    required: true,
                    message: "Lūdzu ievadiet pašreizējo paroli!",
                }],
                onChange: () => { this.onValueChanged('old_password') }
            })
        };

        const newPasswordInput = {
            ...getFieldProps("new_password", {
                rules: [{
                    required: true,
                    message: "Lūdzu ievadiet jauno paroli!",
                }],
                onChange: () => { this.onValueChanged('new_password') }
            })
        };

        const newPasswordRepeatInput = {
            ...getFieldProps("new_password_repeat", {
                rules: [{
                    required: true,
                    message: "Lūdzu atkārtojiet jauno paroli!",
                }, (...args) => { if(args[1].trim() !== this.props.form.getFieldValue('new_password')) return ['Paroles nesakrīt!']; return [] }],
                onChange: () => { this.onValueChanged('new_password_repeat') },
                validateFirst: true
            })
        };

        return (
            <div>
                <header style={{ background: "#fff" }}>
                    <div className="layout-wrapper">
                        <HeaderMenu { ...this.props }/>
                    </div>
                </header>

                <div className="layout-wrapper">      
                    <Form loading={this.state.loading} error={this.formHasErrors()}>
                        <Form.Field required>
                            <label>Pašreizējā parole</label>
                            <Input placeholder='Pašreizējā parole' type="password" { ...existingPasswordInput }/>
                            <Message
                                error
                                list={this.fieldErrors('old_password')}
                            />
                        </Form.Field>
                        <Form.Field required>
                            <label>Jaunā parole</label>
                            <Input placeholder='Jaunā parole' type="password" { ...newPasswordInput }/>
                            <Message
                                error
                                list={this.fieldErrors('new_password')}
                            />
                        </Form.Field>
                        <Form.Field required>
                            <label>Atkārtot jauno paroli</label>
                            <Input placeholder='Atkārtot jauno paroli' type="password" { ...newPasswordRepeatInput }/>
                            <Message
                                error
                                list={this.fieldErrors('new_password_repeat')}
                            />
                        </Form.Field>
                        <Button type='submit' onClick={this.onSubmit}>Mainīt paroli</Button>
                    </Form>
                </div>
            </div>
        )
    }
}

export const ChangePassword = createForm()(ChangePasswordWrapper)