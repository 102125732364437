import * as React from "react";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";

interface ResponsiveElementProps {
    desktopProps?: any;
    mobileProps?: any;
    sharedProps?: any;
    children?: any;
}

export class ResponsiveElement extends React.Component<ResponsiveElementProps, {}> {
    render() {
        const childrenWithPropsDesktop = React.cloneElement(this.props.children, { ...this.props.desktopProps, ...this.props.sharedProps });
        const childrenWithPropsMobile = React.cloneElement(this.props.children, { ...this.props.mobileProps, ...this.props.sharedProps });

        return (
            <div style={{ width: "100%" }}>
                <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
                    {childrenWithPropsMobile}
                </Responsive>

                <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                    {childrenWithPropsDesktop}
                </Responsive>
            </div>
        );
    }
}
