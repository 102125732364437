import { LayoutProps } from "../../components/LayoutWrapper";
import * as React from 'react';
import { HeaderMenu } from "../../components/HeaderMenu";
import { Button, Dimmer, Grid, Header, Icon, Input, Loader, Message, Responsive, Segment, Table } from "semantic-ui-react";
import { ResponsiveElement } from "../../components/ResponsiveElement";
import { Helpers } from "../../utils/helpers";
import axios from 'axios';
import { UserInfo, UserInfoForm } from "../../components/UserInfo";
import { groupBy, mapValues } from 'lodash';


interface UserEditState {
    loading: boolean;
    serverErrors: {[field: string]: string[]};
    userData?: UserInfo;
}

interface UserEditProps extends LayoutProps{
    match: any
}

export class UserEdit extends React.Component<UserEditProps, UserEditState> {
    constructor(props) {
        super(props);
        console.log(this.props);
        this.state = {
            loading: false,
            serverErrors: {},
        };
        this.loadUser(props.match.params.userId);
    }

    loadUser = async (id: number) => {
        await new Promise(resolve => setTimeout(resolve, 1000));
        this.setState({ loading: true});
        
        
        const user = await axios.get(`/api/users/${id}`, { headers: {
            Authorization: Helpers.getSessionId()
        }});

        this.setState({ userData: user.data.data, loading: false });
    }

    createUser = async (user: UserInfo) => {
        this.setState({
            loading: true
        });
        console.log(user);

        try{
            const response = await axios.patch(`/api/users/${this.props.match.params.userId}`, user, {
                headers: {
                    Authorization: Helpers.getSessionId()
                },
            });
            Helpers.showError("Lietotājs saglabāts!");
        }catch(error){
            
            if(error.response && error.response.status === 400){
                const errors = error.response.data.errors;

                this.setState({
                    serverErrors: mapValues(groupBy(errors,error => error.param), errors => errors.map(error => error.msg))
                })
            }else{
                Helpers.showError("Kaut kas nogāja greizi!");
            }
        }

        this.setState({
            loading: false
        });
    }

    onValueChanged = (field) => {
        const errors = this.state.serverErrors;
        errors[field] = [];
        this.setState({
            serverErrors: errors
        });
    }

    render() {
        let content;
        if(this.state.userData){
            content = <UserInfoForm onSubmit={this.createUser} type='edit' initialUserInfo={this.state.userData} loading={this.state.loading} onValueChanged={this.onValueChanged} serverErrors={this.state.serverErrors}/>;
        }else{
            content = <Dimmer active>
            <Loader>Loading</Loader>
          </Dimmer>
        }

        return (
            <div>
                <header style={{ background: "#fff" }}>
                    <div className="layout-wrapper">
                        <HeaderMenu { ...this.props }/>
                    </div>
                </header>

                <div className="layout-wrapper">      
                    {content}
                </div>
            </div>
        )
    }
}

