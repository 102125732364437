import { LayoutProps } from "../../components/LayoutWrapper";
import * as React from 'react';
import { HeaderMenu } from "../../components/HeaderMenu";
import { Button, Dimmer, Grid, Header, Icon, Input, Loader, Message, Responsive, Segment, Table } from "semantic-ui-react";
import { ResponsiveElement } from "../../components/ResponsiveElement";
import { Helpers } from "../../utils/helpers";
import axios from 'axios';
import { SortimentInfo, SortimentInfoForm } from "../../components/SortimentInfo";
import { groupBy, mapValues } from 'lodash';


interface SortimentEditState {
    loading: boolean;
    serverErrors: {[field: string]: string[]};
    SortimentData?: SortimentInfo;
}

interface SortimentEditProps extends LayoutProps{
    match: any
}

export class SortimentEdit extends React.Component<SortimentEditProps, SortimentEditState> {
    constructor(props) {
        super(props);
        console.log("sortiment edit stat");
        console.log(this.props);
        this.state = {
            loading: false,
            serverErrors: {},
        };
        this.loadSortiment(props.match.params.sortimentId);
    }

    loadSortiment = async (id: number) => {
        await new Promise(resolve => setTimeout(resolve, 1000));
        this.setState({ loading: true});
        
        
        const sortiment = await axios.get(`/api/sortiments/${id}`, { headers: {
            Authorization: Helpers.getSessionId()
        }});

        this.setState({ SortimentData: sortiment.data.data, loading: false });
    }

    createSortiment = async (sortiment: SortimentInfo) => {
        this.setState({
            loading: true
        });
        console.log(sortiment);

        try{
            const response = await axios.patch(`/api/sortiments/${this.props.match.params.sortimentId}`, sortiment, {
                headers: {
                    Authorization: Helpers.getSessionId()
                },
            });
            Helpers.showError("Sortiments saglabāts!");
        }catch(error){
            
            if(error.response && error.response.status === 400){
                const errors = error.response.data.errors;

                this.setState({
                    serverErrors: mapValues(groupBy(errors,error => error.param), errors => errors.map(error => error.msg))
                })
            }else{
                Helpers.showError("Kaut kas nogāja greizi!");
            }
        }

        this.setState({
            loading: false
        });
    }

    onValueChanged = (field) => {
        const errors = this.state.serverErrors;
        errors[field] = [];
        this.setState({
            serverErrors: errors
        });
    }

    render() {
        let content;
        if(this.state.SortimentData){
            content = <SortimentInfoForm onSubmit={this.createSortiment} type='edit' initialSortimentInfo={this.state.SortimentData} loading={this.state.loading} onValueChanged={this.onValueChanged} serverErrors={this.state.serverErrors}/>;
        }else{
            content = <Dimmer active>
            <Loader>Loading</Loader>
          </Dimmer>
        }

        return (
            <div>
                <header style={{ background: "#fff" }}>
                    <div className="layout-wrapper">
                        <HeaderMenu { ...this.props }/>
                    </div>
                </header>

                <div className="layout-wrapper">      
                    {content}
                </div>
            </div>
        )
    }
}

